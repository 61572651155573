import {
  THREE,
  game,
  type CannonNamedBody,
  errorManager,
  modes
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig
} from './config'
import { ModelsNames } from './types'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Ci ide o predlzenu verziu trate alebo nie */
  public isLongTrack = false

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** Nazov meshu plotu */
  public FENCE_NAME = 'SafetyNet'

  /** Mesh plotu */
  public fenceMesh!: THREE.Mesh

  /** Fyzikalne body plotu */
  public fenceBody!: CannonNamedBody

  /** Pole fyzikalnych meshov */
  public PHYSICS_MESHES = [this.FENCE_NAME]

  /** Ci uz bol schovany start */
  private startHidden = false

  /** Ci uz bol zobrazeny ciel */
  private finishShown = false

  /**
   * Nastavenie dlzky trate
   */
  public setUpTrackLength(): void {

    // predlzena trat bude v DL a boss sutazi
    this.isLongTrack = modes.isBossCompetition() || modes.isDailyLeague() || modes.isTournament()

  }

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()
    this.createFencePhysics()
    // this.createFinishGatePhysics()

    console.log('HILL vytvoreny....')

    game.physics.setWorldConfig({
      gravitation: gameConfig.gravitation
    })

  }

  /**
   * Nastavenie viditelnosti meshov v cieli
   * @param visibility - True, ak ma byt viditelny
   */
  public setVisibilityFinishMeshes(visibility: boolean): void {

    if (!this.isLongTrack) return

    game.getMesh('FinishZoneBuildings').visible = visibility
    game.getMesh('FinishGate').visible = visibility
    game.getMesh('FinishZoneStandingAudience').visible = visibility
    game.getMesh('FinishZoneSittingAudience').visible = visibility
    game.getMesh('Reflectors').visible = visibility
    game.getMesh('LowVillage').visible = visibility

  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    const meshHillName = modelsConfig[
      this.isLongTrack ? ModelsNames.hillLong : ModelsNames.hill
    ]?.mainMeshNames?.[0]
    if (!meshHillName) {

      throw new Error(errorManager.showBox('Mesh name for hill was not defined'))

    }

    this.hillMesh = game.getMesh(meshHillName)
    this.hillMesh.visible = false

    const material: THREE.MeshBasicMaterial = this.hillMesh.material as THREE.MeshBasicMaterial
    material.vertexColors = true

    const physicsTrackParts = this.isLongTrack ? 40 : 20

    for (let i = 1; i <= physicsTrackParts; i++) {

      let computedTrack
      if (i < 10) {

        computedTrack = `0${i}`

      } else {

        computedTrack = i

      }
      const physicsName = `Physics_Track_${computedTrack}`
      const physicsMesh = game.getMesh(physicsName)
      physicsMesh.material = material
      physicsMesh.visible = false
      const hillBody = game.physics.addBodyFromMesh(physicsName, physicsMesh, 0)
      this.hillBodies.push(hillBody)

    }

  }

  /**
   * Vytvorenie fyziky plotu
   */
  private createFencePhysics(): void {

    const name = this.FENCE_NAME

    this.fenceMesh = game.getMesh(name)
    this.fenceBody = game.physics.addBodyFromMesh(name, this.fenceMesh, 0)

  }

  /**
   * Spravovanie casti kopca kvoli optimalizacii
   * @param part - o aku cast ide
   */
  public manageHillParts(part: string): void {

    // ide zatial iba o dlhu trat
    if (!this.isLongTrack) return

    const partNumber = Number(part)
    const partNumberTerrain = Math.ceil(partNumber / 4)
    const showStart = partNumber < 8
    const showFinish = partNumber >= 36

    if (!this.startHidden && !showStart) {

      this.startHidden = true
      game.getMesh('StartCabinEnviroment').visible = false
      game.getMesh('StartCabin').visible = false
      game.getMesh('UpperTrackAudience').visible = false

    }

    if (!this.finishShown && showFinish) {

      this.finishShown = true
      this.setVisibilityFinishMeshes(true)

    }

    // zobrazeny bude 1 cast za nami, tam kde sme a este 1-3 pred nami
    for (let i = 1; i <= 10; i += 1) {

      const prefix = i < 10 ? '0' : ''
      const mesh = game.getMesh(`Terrain_${prefix}${i}`)
      mesh.visible = i >= partNumberTerrain - 1 && i <= partNumberTerrain + 2

    }


  }

}

export const hill = new Hill()
