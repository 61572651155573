import {
  inputsState,
  startPhaseState
} from '@/stores'
import {
  THREE,
  AnimationsManager,
  game,
  CallbackAnimationTypes,
  CANNON,
  gameStats,
  audioManager,
  errorManager,
  playersManager,
  CameraStates,
  tutorialManager,
  gsap,
  cameraManager,
  fpsManager,
  modes
} from '@powerplay/core-minigames'
import type {
  CannonNamedBody,
  CollisionEvent,
  ContactEvent
} from '@powerplay/core-minigames'
import type { ContactMaterial } from 'cannon-es'
import {
  modelsConfig,
  gameConfig,
  animationsConfig
} from '../config'
import { gatesManager } from '../GatesManager'
import { hill } from '../Hill'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import type { FinishPhaseManager } from '../phases/FinishPhaseManager'
import {
  ModelsNames,
  PlayerAnimationsNames,
  type JumpStates,
  type PositionTuple,
  AudioNames,
  DisciplinePhases
} from '../types'
import { PlayerMovementAnimationWeightManager } from './PlayerMovementAnimationWeightManager'
import { PlayerVelocityManager } from './PlayerVelocityManager'

/**
 * Trieda pre hraca
 */
export class Player {

  /** Stavy pre skok */
  public jumpStates: JumpStates = {
    inAir: false,
    framesInAir: 0,
    framesOnGround: 1,
    inAirAnimationPlaying: false,
    FRAMES_LIMIT: 5
  }

  /** 3D objekt lyziara - cela scena */
  public playerObject: THREE.Object3D = new THREE.Object3D()

  /** Manager pre animacie */
  public animationsManager!: AnimationsManager

  /** Fyzicke body pre objekt */
  public physicsBody!: CannonNamedBody

  /** Fyzicke body pre kolizie s inymi objektami */
  private collisionBodyGates!: CannonNamedBody

  /** Fyzicke body pre kolizie s inymi objektami */
  private collisionBodyCheckpoints!: CannonNamedBody

  /** Raycaster pre natocenie lyziara */
  private raycaster: THREE.Raycaster = new THREE.Raycaster()

  /** Pomocny vektor */
  private tempVector: THREE.Vector3 = new THREE.Vector3()

  /** 3D objekt, v ktorom sa nachadza kamera a ktoremu sa meni pozicia lerpom postupne */
  private goalObject: THREE.Object3D = new THREE.Object3D()

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private rotateLerp: THREE.Vector3 = new THREE.Vector3()

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private rotateLerpTo: THREE.Vector3 = new THREE.Vector3()

  /** Pomocny vektor na lerp na normalu svahu */
  private normalLerp: THREE.Vector3 = new THREE.Vector3()

  /** Pomocny vektor na lerp na normalu svahu */
  private normalLerpTo: THREE.Vector3 = new THREE.Vector3()

  /** Posledna hodnota velocity na Y */
  private lastVelocityY = 0

  /** ci prebieha endphase */
  private endPhase = false

  /** hrac je skrceny */
  public isCrouching = false

  /** hrac sa rozbieha */
  public isSkating = false

  /** Material na spravanie sa players */
  private playerPhysicsMaterial: CANNON.Material = new CANNON.Material('Player')

  /** Upravuje vysku hraca nad tratou - iba pri starte */
  private readonly SKIER_POSITION_Y_ADJUST_START = -1.4

  /** Upravuje vysku hraca nad tratou */
  private readonly SKIER_POSITION_Y_ADJUST = -1.2

  /** Hracova vzdialenost na Y */
  private playerDistanceY = 0

  /** Data pre nahybanie sa - zatial len jedno */
  private playerMovementAnimationWeight = new PlayerMovementAnimationWeightManager()

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** Ci bol prepad pod trat alebo nie */
  public outOfBounds = false

  /** callback pri kolizii */
  private collisionEndCallback!: () => unknown

  /** Manager rychlosti */
  public velocityManager = new PlayerVelocityManager()

  /** Kontakt material medzi kopcom a hracom */
  private contactMaterialHillPlayer!: ContactMaterial

  /** Vzdialenost od priesecnika povrchu */
  private intersectionDistance = 0

  /** Normala priesecnika s povrchom */
  private intersectionNormal = new THREE.Vector3()

  /** Bod priesecnika */
  private intersectionPoint = new THREE.Vector3()

  /** casovac vypadnutia z trate */
  private outOfBoundsTimer: gsap.core.Tween | undefined

  /** kolko frameov sme stucknuty */
  private totalFramesStuck = 0

  /** ci sme momentalne zaseknuty */
  public isStuck = false

  /** UUID spera */
  public uuid = ''

  /** startova pozicia hraca */
  public startPosition!: CANNON.Vec3

  /** po kolkych framoch resetneme hru */
  private FRAMES_STUCK_RESET_GAME = 150

  /** cim predelime velocity ktoru aplikujeme ako impulz pri naraze */
  private BOUNCE_COEF = -8

  /**
   * Vytvorenie lyziara
   * @param position - Startovacia pozicia lyziara
   */
  public create(position?: CANNON.Vec3): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    // pokial sme dostali poziciu, tak ta je prvorada, inak davame default podla dlzky trate
    if (position) {

      this.startPosition = position

    } else {

      this.startPosition = hill.isLongTrack ?
        gameConfig.startPositionLongTrack :
        gameConfig.startPosition

    }

    const meshSkierName = modelsConfig[ModelsNames.skier]?.mainMeshNames?.[0]
    if (!meshSkierName) {

      throw new Error(errorManager.showBox('Mesh name for skier was not defined'))

    }

    this.playerObject = game.getObject3D(meshSkierName)

    game.scene.add(this.playerObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.playerObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    this.goalObject.position.set(
      this.startPosition.x,
      this.startPosition.y,
      this.startPosition.z + 2
    )

    // threeJS Section
    this.playerObject.position.set(
      this.startPosition.x,
      this.startPosition.y + this.SKIER_POSITION_Y_ADJUST_START,
      this.startPosition.z
    )
    this.playerObject.rotation.set(-0.2, Math.PI, 0)
    this.playerObject.name = 'Player'

    // CannonJS Section
    this.createAndSetPhysicsBody(this.startPosition)

    this.setupAttributesGD()

    // tiene
    game.shadowsManager.attachPlaneToObject(this.playerObject)

    console.log('hrac vytvoreny...')

  }

  /**
   * Vytvorenie a nastavenie fyzickych veci
   * @param position - Pozicia lyziara
   */
  private createAndSetPhysicsBody(position: CANNON.Vec3): void {

    const shape = new CANNON.Box(new CANNON.Vec3(1, 0.5, 1))
    this.physicsBody = new CANNON.Body({
      mass: gameConfig.playerMass,
      material: this.playerPhysicsMaterial,
      shape
    }) as CannonNamedBody

    const sphereShape = new CANNON.Sphere(1)
    const coefPositionSphere = 0.5

    const sphereShapePositions: PositionTuple[] = [[1, 1], [1, -1], [-1, 1], [-1, -1]]

    sphereShapePositions.forEach((element: PositionTuple) => {

      this.physicsBody.addShape(
        sphereShape,
        new CANNON.Vec3(

          coefPositionSphere * element[0],
          -0.5,
          // 0,
          coefPositionSphere * element[1]

        )
      )

    })

    this.physicsBody.name = this.playerObject.name
    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.physicsBody.position.set(
      position.x,
      position.y,
      position.z
    )
    /*
     * this.physicsBody.quaternion.set(
     *     this.playerObject.quaternion.x,
     *     this.playerObject.quaternion.y,
     *     this.playerObject.quaternion.z,
     *     this.playerObject.quaternion.w
     * )
     * toto zabezpecuje, ze sa predmet nekotula
     */
    this.physicsBody.angularFactor = new CANNON.Vec3(0, 0, 0)
    game.physics.addBody(this.physicsBody)
    console.log('TOTO JE FYZIKA', game.physics)

    this.createCollisionBodyGates()
    this.createCollisionBodyCheckpoints()
    this.setupCollision()

  }

  /**
   * Vytvorenie kolizneho bodycka
   */
  private createCollisionBodyGates(): void {

    const shape = new CANNON.Sphere(0.2)
    this.collisionBodyGates = new CANNON.Body({
      mass: 0,
      material: this.playerPhysicsMaterial,
      shape,
      isTrigger: true,
      collisionFilterGroup: 2,
      collisionFilterMask: 2
    }) as CannonNamedBody


    const sphereShapePositions: PositionTuple[] = [[0, 1], [0, 0.6], [0, 0.2], [0, -0.2]]

    sphereShapePositions.forEach((element: PositionTuple) => {

      this.collisionBodyGates.addShape(
        shape,
        new CANNON.Vec3(

          element[0],
          0,
          element[1]

        )
      )

    })

    this.collisionBodyGates.name = 'collisionPlayerBodyGates'
    this.collisionBodyGates.type = CANNON.BODY_TYPES.DYNAMIC
    // this.collisionBodyGates.isTrigger = true
    this.collisionBodyGates.position.set(
      this.physicsBody.position.x,
      this.physicsBody.position.y - 0.25,
      this.physicsBody.position.z
    )
    game.physics.addBody(this.collisionBodyGates)

  }

  /**
   * Vytvorenie kolizneho bodycka
   */
  private createCollisionBodyCheckpoints(): void {

    if (!modes.isTrainingMode()) return

    const shape = new CANNON.Sphere(1)
    this.collisionBodyCheckpoints = new CANNON.Body({
      mass: 0,
      material: this.playerPhysicsMaterial,
      shape,
      isTrigger: true,
      collisionFilterGroup: 3,
      collisionFilterMask: 3
    }) as CannonNamedBody

    this.collisionBodyCheckpoints.name = 'collisionPlayerBodyCheckpoints'
    this.collisionBodyCheckpoints.type = CANNON.BODY_TYPES.DYNAMIC
    // this.collisionBodyCheckpoints.isTrigger = true
    this.collisionBodyCheckpoints.position.set(
      this.physicsBody.position.x,
      this.physicsBody.position.y - 0.25,
      this.physicsBody.position.z
    )
    game.physics.addBody(this.collisionBodyCheckpoints)

  }

  /**
   * Nastavenie spravania pri kolizii hraca s inymi objektami
   */
  private setupCollision = (): void => {

    this.physicsBody.addEventListener('collide', (e: CollisionEvent) => {

      const bodyName = e.body.name ?? ''

      // skontrolujeme si, ci ide o physics track
      const splitArr = bodyName.split('Physics_Track_')
      if (splitArr[0] === '' && !isNaN(Number(splitArr[1]))) {

        // poriesime trat, ako ma byt
        hill.manageHillParts(splitArr[1])

      }

      if (hill.PHYSICS_MESHES.includes(bodyName)) {

        /*
         * mute all sounds - momentalne sa hra neukoncuje, tak netreba vypinat
         * audioManager.stopAudioByName(AudioNames.skiingLoop)
         * audioManager.stopAudioByName(AudioNames.skiingJump)
         * audioManager.stopAudioByName(AudioNames.wind)
         */

        /*
         * TODO GAME OVER
         * call finishPhase on EndPhaseManager
         */

        this.collisionEndCallback()

      }

    })

    let beginContactVelocityY = 0

    game.physics.getPhysicsWorld.addEventListener('beginContact', (e: ContactEvent) => {

      if (!e.bodyA.name || !e.bodyB.name) return

      this.setPlayerIsStuck(e.bodyA, e.bodyB, true)

      if (!e.bodyA.name.includes('gate') && !e.bodyB.name.includes('gate')) {

        beginContactVelocityY = this.physicsBody.velocity.y

      }

    })

    game.physics.getPhysicsWorld.addEventListener('endContact', (e: ContactEvent) => {

      if (!e.bodyA.name || !e.bodyB.name) return

      this.setPlayerIsStuck(e.bodyA, e.bodyB, false)

      if (!e.bodyA.name.includes('gate') && !e.bodyB.name.includes('gate')) {

        const newAddValue = (this.physicsBody.velocity.y - beginContactVelocityY) *
                    gameConfig.endContactCoef
        this.physicsBody.velocity.y = beginContactVelocityY + newAddValue

      }

    })

  }

  /**
   * Nastavime ze ci je player stucknuty
   * @param bodyA - kontakt body A
   * @param bodyB - kontakt body B
   * @param stuck - ci mame nastavit stuck/unstuck
   */
  private setPlayerIsStuck(
    bodyA: CannonNamedBody,
    bodyB: CannonNamedBody,
    stuck: boolean
  ): void {

    if (!bodyA.name || !bodyB.name) return

    const isFence = bodyA.name.includes(hill.FENCE_NAME) ||
            bodyB.name.includes(hill.FENCE_NAME)

    const isPlayer = player.physicsBody.name &&
            (bodyA.name.includes(player.physicsBody.name) ||
                bodyB.name.includes(player.physicsBody.name))

    if (!isFence || !isPlayer) return

    this.isStuck = stuck

  }

  /**
   * Aktualizovanie veci podla konfigu od GD
   */
  private setupAttributesGD(): void {

    console.log('GAMECONFIG', gameConfig)
    const hillPhysicsMaterials = game.physics.getPhysicsWorld.bodies
      .filter((body: CannonNamedBody) => body.name?.includes('Physics_Track_'))
    console.log('hillPhysicsMaterials', hillPhysicsMaterials)
    console.log('BODIES', game.physics.getPhysicsWorld.bodies)

    if (hillPhysicsMaterials.length === 0) {

      throw new Error(errorManager.showBox('No hill material'))

    }

    console.warn('configObj', gameConfig)
    hillPhysicsMaterials.forEach(body => {

      if (!body.material) {

        throw Error('No material on physics body')

      }
      const contactMaterial = new CANNON.ContactMaterial(
        body.material,
        this.playerPhysicsMaterial,
        {
          restitution: gameConfig.restitutionHillPlayer,
          friction: gameConfig.frictionHillPlayer,
          frictionEquationRelaxation: gameConfig.frictionEquationRelaxationHillPlayer,
          frictionEquationStiffness: gameConfig.frictionEquationStiffnessHillPlayer,
          contactEquationRelaxation: gameConfig.contactEquationRelaxationHillPlayer,
          contactEquationStiffness: gameConfig.contactEquationStiffnessHillPlayer
        }
      )
      game.physics.getPhysicsWorld.addContactMaterial(contactMaterial)

    })

    // this.contactMaterialHillPlayer = contactMaterial
    this.physicsBody.linearDamping = gameConfig.linearDamping
    this.velocityManager.setAttributesGD()

  }

  /**
   * Aktualizovanie pozicie lyziara
   */
  private updatePlayerPosition(): void {

    const { position } = this.physicsBody

    const adjustY = cameraManager.isThisCameraState(CameraStates.disciplineIntro) ?
      this.SKIER_POSITION_Y_ADJUST_START :
      this.SKIER_POSITION_Y_ADJUST

    this.playerObject.position.set(
      position.x,
      position.y + adjustY,
      position.z
    )

    if (this.collisionBodyCheckpoints) {

      this.collisionBodyCheckpoints.position.set(
        position.x,
        position.y - 0.25,
        position.z
      )

    }
    const rightToe = this.playerObject.getObjectByName('toe_endR')
    if (rightToe) {

      const positionR = this.playerObject.getWorldPosition(rightToe.position)
      this.collisionBodyGates.position.set(positionR.x, positionR.y, positionR.z)

    }

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.playerObject.quaternion

  }

  /**
   * Aktualizovanie pohybovych animacii lyziara
   */
  private updateMovementAnimations(): void {

    if (!this.activeUpdatingMovementAnimations) return

    this.manageJumpFrames()

    if (this.jumpStates.inAirAnimationPlaying && this.jumpStates.inAir) return

    if (
      !this.jumpStates.inAirAnimationPlaying &&
            this.jumpStates.framesInAir >= this.jumpStates.FRAMES_LIMIT
    ) {

      this.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.jump,
        CallbackAnimationTypes.end,
        () => {

          if (!this.activeUpdatingMovementAnimations) return

          this.animationsManager.reset(PlayerAnimationsNames.jump)
          this.animationsManager.changeTo(PlayerAnimationsNames.carve)
          this.jumpStates.inAirAnimationPlaying = false

        }
      )
      this.animationsManager.changeTo(PlayerAnimationsNames.jump)
      this.jumpStates.inAirAnimationPlaying = true

      return

    }

    if (this.jumpStates.framesOnGround >= this.jumpStates.FRAMES_LIMIT) {

      this.animationsManager.changeTo(PlayerAnimationsNames.carve)
      this.jumpStates.inAirAnimationPlaying = false

    }

    if (this.jumpStates.inAir || this.jumpStates.inAirAnimationPlaying) return

    this.playerMovementAnimationWeight.applyWeights(this.isCrouching)

  }

  /**
   * Pocitanie framov vo vzduchu a na zemi
   */
  private manageJumpFrames(): void {

    if (this.jumpStates.inAir) {

      if (this.jumpStates.framesInAir === 0) {

        audioManager.changeAudioVolume(AudioNames.skiingLoop, 0)
        audioManager.play(AudioNames.wind)
        audioManager.play(AudioNames.skiingJump)

      }

      this.jumpStates.framesOnGround = 0
      if (this.jumpStates.framesInAir < this.jumpStates.FRAMES_LIMIT) {

        this.jumpStates.framesInAir += 1

      }

    } else {

      if (inputsState().disabled) inputsState().disabled = false

      if (this.jumpStates.framesOnGround === 0) {

        audioManager.stopAudioByName(AudioNames.skiingJump)
        audioManager.stopAudioByName(AudioNames.wind)
        audioManager.play(AudioNames.skiingLanding)
        audioManager.changeAudioVolume(AudioNames.skiingLoop, 1)

      }

      this.jumpStates.framesInAir = 0
      if (this.jumpStates.framesOnGround < this.jumpStates.FRAMES_LIMIT) {

        this.jumpStates.framesOnGround += 1

      }

    }

  }

  /**
   * Player end animation
   * @param emotionAnimation - Animacia emocie
   */
  public endAnimation(emotionAnimation: PlayerAnimationsNames): void {

    this.animationsManager.setSpeed(0.75)

    this.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.stop,
      CallbackAnimationTypes.end,
      () => {

        this.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.stop,
          CallbackAnimationTypes.end
        )

        this.animationsManager.addAnimationCallback(
          emotionAnimation,
          CallbackAnimationTypes.loop,
          this.endFinishPhaseAfterEndAnimationDone.bind(this, emotionAnimation)
        )
        console.warn('konci stop')
        // this.animationsManager.changeTo(emotionAnimation)
        this.animationsManager.crossfadeTo(emotionAnimation, 0.01, true, false)

      }
    )

    this.animationsManager.changeTo(PlayerAnimationsNames.stop)

    // this.animationsManager.changeTo(emotionAnimation)

    // zvuk divakov
    let emotionSound
    if (emotionAnimation === PlayerAnimationsNames.happy) emotionSound = AudioNames.audienceYay
    if (emotionAnimation === PlayerAnimationsNames.bad) emotionSound = AudioNames.audienceSad

    if (emotionSound) audioManager.play(emotionSound)

  }

  /**
   * Ukoncenie fazy finishu, ked skonci konecna animacia
   */
  private endFinishPhaseAfterEndAnimationDone = (animation: string): void => {

    this.animationsManager.removeAnimationCallback(
      animation,
      CallbackAnimationTypes.loop
    )

    this.animationsManager.resetSpeed()
    this.animationsManager.freezeAnimationToEndOfAnimaton(animation)

    const finishPhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.finish) as FinishPhaseManager

    // ak bolo predcacne ukoncenie nespustame tween
    if (finishPhase.isPrematureExit) return

    finishPhase.setFinishPhaseTween()

  }

  /**
   * Vypocitanie priesecnika s povrchom
   * @param hillMesh - Mesh kopca
   */
  private calculateIntersectionWithGround(hillMesh: THREE.Mesh): void {

    // reset hodnot
    this.intersectionDistance = 0
    this.intersectionNormal.set(0, 0, 0)
    this.intersectionPoint.set(0, 0, 0)

    // Vzdialenost davame 100m, aby sme urcite pretali zem
    const distance = 100

    this.tempVector.set(
      this.playerObject.position.x,
      this.playerObject.position.y + distance,
      this.playerObject.position.z
    )

    this.raycaster.set(this.tempVector, new THREE.Vector3(0, -1, 0))

    const intersects = this.raycaster.intersectObject(hillMesh)

    if (intersects?.[0]?.distance === undefined) {

      this.isUnderHill()

    }

    // Ak existuje prvy priesecnik, tak mame vzdialenost
    if (intersects?.[0]?.distance) {

      const intersectsDistance = intersects?.[0]?.distance
      this.intersectionDistance = intersectsDistance - distance

      this.checkIsUnderHill()

    }

    // Ak existuje prvy priesecnik, tak mame normalu pre natacanie
    if (intersects?.[0]?.face?.normal) {

      this.intersectionNormal = intersects?.[0]?.face?.normal

    }

    // bod prieniku
    if (intersects?.[0]?.point) {

      this.intersectionPoint.copy(intersects?.[0]?.point)

    }

  }

  /**
   * Nastavenie, ci je hrac vo vzduchu
   */
  private setIsInAir(): void {

    this.jumpStates.inAir = this.intersectionDistance > gameConfig.distanceToBeInAir

  }

  /**
   * kontrola ci je hrac pod kopcom, ak nie je, vynulovat timer
   */
  private checkIsUnderHill(): void {

    if (this.intersectionDistance < -gameConfig.depthOutOfBounds) {

      this.isUnderHill()
      return

    }

    if (this.outOfBoundsTimer === undefined) return

    this.outOfBoundsTimer?.kill()
    this.outOfBoundsTimer = undefined

  }

  /**
   * funkcia na nastavenie timera a vypnutia hry po jeho naplneni
   */
  private isUnderHill(): void {

    if (this.outOfBoundsTimer) return

    this.outOfBoundsTimer = gsap.to({}, {
      onComplete: () => {

        this.outOfBounds = true
        gameStats.setExitedGame(true)
        game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
        console.warn('Out of bounds, game ended')

      },
      duration: gameConfig.outOfBoundsSeconds
    })

  }

  /**
   * Prilepenie hraca na zem, ked treba, inokedy trosku dany vyssie, aby bol skok
   */
  private stickPlayerOnGroundOrJump(): void {

    const intersectionDiff = this.intersectionDistance -
            (gameConfig.playerModelOffset + gameConfig.landingValueFix)

    if (this.playerDistanceY > 0 && (gameConfig.distanceToUnstick < intersectionDiff)) {

      this.playerObject.position.y -=
                (this.playerDistanceY - (gameConfig.playerModelOffset + 0.2))

      if (
        this.playerDistanceY < this.intersectionDistance -
                (gameConfig.playerModelOffset + gameConfig.landingValueFix)
      ) {

        this.playerDistanceY += 0.1

      } else if (
        this.playerDistanceY - 0.1 < this.intersectionDistance -
                (gameConfig.playerModelOffset + gameConfig.landingValueFix)
      ) {

        this.playerDistanceY -= 0.1

      }
      // game.shadowsManager.adjustPositionY((intersectsDistance * -1) + 0.3)

    } else {

      this.playerDistanceY = this.intersectionDistance
      this.playerObject.position.y -=
                (this.intersectionDistance - gameConfig.playerModelOffset)
      // game.shadowsManager.adjustPositionY(0)

    }

  }

  /**
   * Zmena gravitacie
   */
  private changeGravity(): void {

    const {
      maxDistanceForGravityCoefinAirNearTrack, gravitation, gravityCoefInAirNearTrack,
      gravityCoefInAir, minDistanceForGravityCoefinAirNearTrack
    } = gameConfig

    let offset = 0
    if (
      this.intersectionDistance > minDistanceForGravityCoefinAirNearTrack &&
            this.intersectionDistance < maxDistanceForGravityCoefinAirNearTrack
    ) {

      offset = gravityCoefInAirNearTrack

    }
    if (this.jumpStates.inAir) offset = gravityCoefInAir

    game.physics.getPhysicsWorld.gravity.y = gravitation.y + offset

  }

  /**
   * Otocenie hraca podla terenu a smeru lyziara
   */
  private rotatePlayerInVelocityDirection(): void {

    const phases = [DisciplinePhases.preStart, DisciplinePhases.start, DisciplinePhases.finish]
    if (
      phases.includes(disciplinePhasesManager.actualPhase) &&
      !cameraManager.isThisCameraState(CameraStates.discipline)
    ) return

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)

    // nastavime si rotaciu hraca, aby sme podla toho mohli davat dobry smer hraca
    this.velocityManager.setLastRotationFromVelocity(this.physicsBody.velocity)

    // lerpujeme normaly kopca
    this.normalLerpTo.set(
      this.intersectionNormal.x,
      this.intersectionNormal.y,
      this.intersectionNormal.z
    )

    let lerpHillCoef = this.jumpStates.inAir ?
      gameConfig.hillNormalLerpCoefInAir :
      gameConfig.hillNormalLerpCoef

    if (instalerp) lerpHillCoef = 1

    this.normalLerp.lerp(this.normalLerpTo, lerpHillCoef)

    // musime nastavit up vektor, aby sa spravne rotovalo
    this.playerObject.up.set(
      this.normalLerp.x,
      this.normalLerp.y,
      this.normalLerp.z
    )

    if (cameraManager.isThisCameraState(CameraStates.discipline)) {

      cameraManager.getMainCamera().up.set(
        this.normalLerp.x,
        this.normalLerp.y,
        this.normalLerp.z
      )

    }

    const { velocity } = this.velocityManager
    const velocityAddZ = (velocity.x === 0 && velocity.y === 0 && velocity.z === 0) ? -1 : 0

    this.lastVelocityY = THREE.MathUtils.lerp(
      this.lastVelocityY,
      velocity.y,
      instalerp ? 1 : gameConfig.velocityYLerpCoef
    )

    // ku kopii velocity pridame poziciu hraca, aby sme mali spravny bod na lookAt
    this.rotateLerpTo.set(
      this.velocityManager.velocity.x,
      this.lastVelocityY, // this.velocityManager.velocity.y,
      this.velocityManager.velocity.z + velocityAddZ
    ).add(this.playerObject.position)

    // spravime lerp podla nastaveneho kroku
    this.rotateLerp.lerp(this.rotateLerpTo, instalerp ? 1 : gameConfig.playerRotationLerpCoef)

    // na konci sa pozrieme na objekt pred nami, aby sme boli spravne narotovany podla velocity
    this.playerObject.lookAt(this.rotateLerp)

    // este upravime aj fyzikalne objekty, tu je otazka, ci to ma nejaky zmysel..?
    this.physicsBody.quaternion.set(
      this.playerObject.quaternion.x,
      this.playerObject.quaternion.y,
      this.playerObject.quaternion.z,
      this.playerObject.quaternion.w
    )
    this.collisionBodyGates.quaternion.set(
      this.playerObject.quaternion.x,
      this.playerObject.quaternion.y,
      this.playerObject.quaternion.z,
      this.playerObject.quaternion.w
    )

  }

  /**
   * Ziskanie rychlosti hraca
   * @returns - rychlost hraca ako cislo
   */
  public getSpeed(): number {

    const velocity = this.velocityManager.getVelocity()
    return Math.sqrt((velocity.x ** 2) + (velocity.y ** 2) + (velocity.z ** 2))

  }

  /**
   * Vratenie pozicie lyziara
   * @returns Pozicia lyziara
   */
  public getPosition(): THREE.Vector3 {

    return this.playerObject.position

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   */
  public updateBeforePhysics(): void {

    // nic

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   * @param hillMesh - Mesh kopca
   */
  public updateAfterPhysics(hillMesh: THREE.Mesh): void {

    this.velocityManager.update(this.jumpStates.inAir)

    this.updateMovementAnimations()
    this.calculateIntersectionWithGround(hillMesh)
    if (!this.endPhase) {

      this.setIsInAir()
      this.stickPlayerOnGroundOrJump()
      this.changeGravity()

    }

    this.updatePlayerPosition()
    this.rotatePlayerInVelocityDirection()

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.game) this.handleStuck()

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Setter
   * @param phasesManager - phasesManager
   */
  public setCollisionEndCallback(collisionEndCallback: () => unknown): Player {

    this.collisionEndCallback = collisionEndCallback
    return this

  }

  /**
   * Spustenie animacie odrazenia na zaciatku
   */
  public launchStartAnimation = (): void => {

    this.physicsBody.type = CANNON.BODY_TYPES.DYNAMIC
    const { minStartVelocity, maxStartBarBonus, maxAtrBonusStart } = gameConfig

    const barBonus = (maxStartBarBonus / 100) * startPhaseState().clickedPower

    const attributeBonus = playersManager.getPlayer().attribute.total / 2000 * maxAtrBonusStart

    const startVelocity = gameConfig.startVelocityDirection.clone()

    const startVelocityValue = minStartVelocity + barBonus + attributeBonus

    // startovaciu velocity musime dat do 2 smerov ak treba
    if (startVelocity.x) {

      const coef = startVelocityValue / Math.sqrt(startVelocity.x ** 2 + startVelocity.z ** 2)
      startVelocity.x *= coef
      startVelocity.z *= coef

    } else {

      startVelocity.z *= startVelocityValue

    }

    this.physicsBody.velocity = this.velocityManager.getNewVelocity(startVelocity, this.jumpStates.inAir, true)
    console.log('START velocity:', this.physicsBody.velocity)

    audioManager.play(AudioNames.skiingStart)

    console.log('START ANIMATION')
    this.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.end,
      () => {

        console.log('START ANIMATION -- loop')
        this.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.start,
          CallbackAnimationTypes.end
        )
        this.startSkatingAnimation()

      }
    )

    this.animationsManager.changeTo(PlayerAnimationsNames.start)

  }

  /**
   * Spustenie animacie korculovania na zaciatku
   */
  public startSkatingAnimation = (): void => {

    console.log('SKATING ANIMATION - start')
    this.isSkating = true

    this.animationsManager.crossfadeTo(PlayerAnimationsNames.skating, 0.01, true, false)

  }

  /**
   * Skoncenie korculovania na zaciatku
   */
  public endSkating(/* event: THREE.Event */): void {

    tutorialManager.nextSection()
    console.log('SKATING ANIMATION - end')
    this.isSkating = false
    this.activeUpdatingMovementAnimations = true

    this.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.carve,
      CallbackAnimationTypes.crossfade,
      () => {

        console.log('START ANIMATION -- end crossfade')
        this.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.carve,
          CallbackAnimationTypes.crossfade
        )
        audioManager.stopAudioByName(AudioNames.skiingStart)
        audioManager.play(AudioNames.skiingLoop)

      }
    )

    this.animationsManager.crossfadeTo(
      PlayerAnimationsNames.carve,
      0.5,
      true,
      false
    )

    // iba debug poskocenie na finish branku
    if (gameConfig.skipToFinish.active) gatesManager.skipToFinishGate()

  }

  /**
   * Konecna akcia pre hraca
   * @param fall - ci bol pad alebo nie
   * @param emotion - Typ emocie
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false
    this.endPhase = true

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

    this.playerMovementAnimationWeight.applyZeroWeightToAll()

  }

  /**
   * changes config of camera
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * bounce away from obstacle
   */
  public bounceAway(): void {

    const collisionVelocity = this.physicsBody.velocity.clone()

    collisionVelocity.x /= this.BOUNCE_COEF
    collisionVelocity.y = 0
    collisionVelocity.z /= this.BOUNCE_COEF

    this.physicsBody.applyImpulse(collisionVelocity)

  }

  /**
   * kontrola ci sme sa stuckli
   */
  private handleStuck(): void {

    if (!this.isStuck) {

      this.totalFramesStuck = 0
      return

    }

    this.totalFramesStuck++

    if (this.totalFramesStuck >= this.FRAMES_STUCK_RESET_GAME) {

      player.outOfBounds = true
      gameStats.setExitedGame(true)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
      console.log('stuck in net, exiting game')

    }

  }

  /**
   * reset hraca
   */
  public reset(): void {

    this.activeUpdatingMovementAnimations = false
    this.velocityManager.reset()
    this.endPhase = false

    // animacie
    this.animationsManager = new AnimationsManager(
      this.playerObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    this.playerMovementAnimationWeight.applyZeroWeightToAll()
    this.playerMovementAnimationWeight = new PlayerMovementAnimationWeightManager()

    this.physicsBody.velocity.set(0, 0, -1)
    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.physicsBody.position.set(
      this.startPosition.x,
      this.startPosition.y,
      this.startPosition.z
    )

  }

}

export const player = new Player()
