import {
  gsap,
  game,
  audioManager,
  CameraStates,
  MobileDetector,
  corePhasesManager,
  modes,
  cameraManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  AudioNames,
  type DisplayMessage,
  PlayerAnimationsNames,
  Tasks,
  TutorialObjectiveIds,
  TutorialEventType,
  AudioGroups
} from '../types'

import {
  actionButtonState,
  inputsState,
  shinyTextsState,
  startMessageState,
  startPhaseState,
  tutorialState,
  uiState
} from '@/stores'
import { player } from '../player'
import { inputsManager } from '../InputsManager'
import { gameConfig } from '../config'
import { trainingTasks } from '../modes/training/TrainingTasks'
import { endManager } from '../EndManager'
import { tutorialObjectives } from '../modes/tutorial/TutorialObjectives'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '../modes/tutorial/TutorialUIChange'
import { hill } from '../Hill'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** casovac pipnuti */
  private soundTimer = 0

  /** pocitadlo pipnuti */
  private soundCounter = 0

  /** tween prveho pipnutia */
  private firstSoundTween!: gsap.core.Tween

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  private startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci faza skoncila */
  private ended = false

  /** Specialne zobrazenie baru v tutoriali */
  private showBarInTutorial = false

  /** Ci ma tutorial updateovat */
  public shouldTutorialUpdate = false

  /** Hack na initial start */
  public isAfterIntro = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween !: gsap.core.Tween

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na zacatie disciplinoveho intra */
  private launchSystemTween!: gsap.core.Tween

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 3

  /** tween na spustenie pipania */
  private startRunSoundTween!: gsap.core.Tween

  /** timeline na spustenie pipania */
  private startRunSoundTimeline!: gsap.core.Timeline

  /** kolkokrat piplo po vystartovani */
  private beepsAfterStart = 0

  /** ako dlho zostane bar zobrazeny po ukonceni fazy */
  private START_BAR_DURATION = 1

  /** Konstruktor */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    inputsState().disabled = true

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    const shinyTexts = [{ name: 'miss',
      active: false }]
    if (modes.isTrainingMode()) {

      shinyTexts.push({ name: 'plus-10-pc',
        active: false })

    }

    shinyTextsState().texts = shinyTexts
    if (this.ended) this.reset()

    console.warn('starting start phase')

    if (!modes.isTutorial()) {

      this.showName = true

      this.firstSoundTween = gsap.to({}, {
        onComplete: this.makeBeep,
        callbackScope: this,
        duration: 1
      })

    }

    this.setCameraForDisciplineIntro()

  }

  /**
   * Pustenie zvuku pipnutia
   */
  private makeBeep = (): void => {

    let audioName = AudioNames.countdownBeepShort
    if (this.soundCounter === 5) {

      audioName = AudioNames.countdownBeepLong

    }

    if (this.ended) this.beepsAfterStart++
    audioManager.play(audioName)
    audioManager.changeAudioVolume(audioName, (1 - (0.1 * this.beepsAfterStart)))

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.changeCameraSettings(
        gameConfig.cameraConfig.idealOffset,
        gameConfig.cameraConfig.idealLookAt,
        gameConfig.cameraConfig.coefSize,
        gameConfig.cameraConfig.changeLerp
      )

    }

    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntroTween()
      tutorialFlow.init()
      tutorialUIChange.init()
      this.shouldTutorialUpdate = true
      return

    }

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntroTween)

  }

  /**
   * Spravenie veci po konci tweenu disciplinoveho intra
   */
  private afterCameraDisciplineIntroTween = (): void => {

    cameraManager.setState(CameraStates.discipline)

    if (!modes.isTutorial()) inputsState().isVisible = true

    player.animationsManager.changeTo(PlayerAnimationsNames.preStart)

    if (modes.isTutorial()) {

      // this.launchSystem()
      tutorialFlow.eventActionTrigger(TutorialEventType.waitingForStartEvent)
      return

    }
    this.launchSystemTween = gsap.to({}, {
      onComplete: this.launchSystem,
      callbackScope: this,
      duration: 0.5
    })

  }

  /**
   * Spustenie pipania
   */
  public launchSystem = (): void => {

    this.skippable = false

    cameraManager.setState(CameraStates.discipline)

    hill.setVisibilityFinishMeshes(false)

    let duration = 0
    if (this.skipped) duration = 1

    this.showName = false

    uiState().$patch({
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    // davam maly delay kvoli tomu, ze predtym mohol byt skip
    this.startRunSoundTween = gsap.to({}, {
      onComplete: () => {

        this.framesInPhase = 0
        this.showBarInTutorial = false

        this.startable = true
        this.isAfterIntro = true
        tutorialState().showBarStart = false
        inputsState().disabled = false
        actionButtonState().isStart = true
        this.runSoundTween()

      },
      duration
    })

  }

  /**
   * Spustenie tweenu pre jedno pipnutie
   */
  private runSoundTween(): void {

    this.soundCounter += 1

    // TODO: toto dat niekam inam, nech to nie je naviazane na nejaky soundCounter? :)
    if (this.soundCounter > 10 && !modes.isTutorial()) {

      this.finishPhase()
      return

    }

    if (this.soundCounter <= 5) {

      gsap.timeline().to(this, {
        onComplete: this.makeBeep,
        callbackScope: this,
        soundTimer: 100,
        duration: 0.5,
        ease: 'power1.out'
      }).to(this, {
        onComplete: this.runSoundTween,
        callbackScope: this,
        soundTimer: 0,
        duration: 0.5,
        ease: 'power1.in'
      })

    } else {

      /*
       * TODO: toto lepsie spravit, nech to tu nemusi byt takto - ale mozno sa upravi, ked
       * to vyssie bude optimalizovane
       */
      if (this.startRunSoundTimeline) this.startRunSoundTimeline.kill()
      this.startRunSoundTimeline = gsap.timeline().to(this, {
        callbackScope: this,
        soundTimer: 100,
        duration: 0.5,
        ease: 'power1.out'
      }).to(this, {
        onComplete: this.runSoundTween,
        callbackScope: this,
        soundTimer: 0,
        duration: 0.5,
        ease: 'power1.in'
      })

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++

    if (MobileDetector.isMobile()) {

      if (actionButtonState().touchStart && this.startable) {

        console.log('bol stlaceny button start')

        this.clickedPower = this.soundTimer
        console.log('clicked power:', this.clickedPower)

        tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)
        // this.storeState()
        this.finishPhase()
        this.ended = true

      }

    } else {

      if (inputsManager.actionPressed && this.startable && this.framesInPhase > 10) {

        console.log('action pressed')

        this.clickedPower = this.soundTimer
        console.log('clicked power:', this.clickedPower)

        tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)
        // this.storeState()
        this.finishPhase()
        this.ended = true

      }

    }

    if (inputsManager.actionPressed && this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      this.firstSoundTween.kill()
      this.framesInPhase = 0

    }

    this.storeState()

  }

  /**
   * kill all tweens
   */
  public killAllTweens(): void {

    if (this.firstSoundTween) this.firstSoundTween.kill()
    if (this.launchSystemTween) this.launchSystemTween.kill()
    if (this.startRunSoundTween) this.startRunSoundTween.kill()
    if (this.startRunSoundTimeline) this.startRunSoundTimeline.kill()

  }

  /**
   * nastavime tween na skrytie start baru
   */
  private hideStartBar(): void {

    gsap.to({}, {
      duration: this.START_BAR_DURATION,
      onComplete: () => {

        startPhaseState().showBar = false

      }
    })

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    // ulozime si hodnotu prvej ulohy v treningu
    trainingTasks.saveTaskValue(Tasks.start, this.clickedPower / 100)

    game.togglePhysics(true)

    if (!this.ended) {

      this.hideStartBar()

      this.killAllTweens()
      console.warn('start phase ended')
      this.showTakeoffMessage()

      this.startable = false
      this.ended = true
      this.callbackEnd()

      this.storeState()

      uiState().$patch({
        showTimeKeeper: true,
        showSplitTimes: !modes.isTrainingMode(),
        showFinishTopBox: false,
        showTrainingLayout: modes.isTrainingMode(),
        isTraining: modes.isTrainingMode()
      })

      inputsState().isVisible = true

      audioManager.stopAudioByGroup(AudioGroups.commentators)
      audioManager.play(AudioNames.commentAfterStart)

    }

  }

  /**
   * UI update
   */
  private storeState(): void {

    startPhaseState().$patch({
      clickedPower: this.clickedPower,
      soundTimer: this.soundTimer,
      showName: this.showName,
      showPhase: !this.ended,
      showBar: (this.startable || this.showBarInTutorial),
      attempt: corePhasesManager.disciplineActualAttempt
    })

  }

  /**
   * zobrazime startovu spravu
   */
  public showTakeoffMessage(): void {

    const message = this.getStartingMessage()

    startMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startMessageState().$patch({
          showMessage: false,
          messageText: message.message,
          messageColor: message.color
        })

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  public getStartingMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }

    if (this.clickedPower >= 99) {

      endManager.perfectStartsLog += 1
      message.message = 'perfectStart'
      message.color = 0

    } else if (this.clickedPower >= 90) {

      message.message = 'excellentStart'
      message.color = 0

    } else if (this.clickedPower >= 70) {

      message.message = 'goodStart'
      message.color = 1

    }

    return message

  }

  /**
   * Zobrazenie start baru v tutoriali
   */
  public showBarTutorial = (): void => {

    this.showBarInTutorial = true
    tutorialState().showBarStart = true
    this.storeState()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    inputsState().disabled = true
    this.soundTimer = 0
    this.soundCounter = 0
    this.skippable = true
    this.startable = false
    this.showName = false
    this.skipped = false
    this.ended = false
    this.clickedPower = 0
    this.framesInPhase = 0
    this.beepsAfterStart = 0
    this.isAfterIntro = false
    this.storeState()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
