import type { MaterialDataObject } from '@powerplay/core-minigames'
import { hill } from '../Hill'
import { checkpointManager } from '../modes/training/CheckpointsManager'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: [
      'body', 'pole_L', 'pole_R', 'ski_L', 'ski_R', 'w_body', 'w_pole_L', 'w_pole_R',
      'w_ski_L', 'w_ski_R'
    ],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.transparent]: {
    alpha: 0.5,
    textureName: TexturesNames.transparent,
    meshesArray: [
      'Trees', 'FinishZoneStandingAudience', 'FinishZoneSittingAudience', 'UpperTrackAudience'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.transparentSafetyNet]: {
    textureName: TexturesNames.transparentSafetyNet,
    transparent: true,
    meshesArray: [hill.FENCE_NAME]
  },
  [MaterialsNames.mountain]: {
    textureName: TexturesNames.mountain,
    meshesArray: ['MountainsBackground'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: ['Ads'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['Flags'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.track,
    meshesArray: [
      'Terrain', 'Track', 'Terrain_01', 'Terrain_02', 'Terrain_03', 'Terrain_04', 'Terrain_05', 'Terrain_06',
      'Terrain_07', 'Terrain_08', 'Terrain_09', 'Terrain_10'
    ],
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true
  },
  [MaterialsNames.people]: {
    textureName: TexturesNames.people,
    meshesArray: ['SoloPeopleAudience'],
    alpha: 0.5,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeople', 'StaticPeopleUP']
  },
  [MaterialsNames.onlyVertexColor]: {
    vertexColors: true,
    meshesArray: [checkpointManager.CHECKPOINT_MESH_NAME]
  },
  [MaterialsNames.checkpointGlow]: {
    meshesArray: [checkpointManager.CHECKPOINT_GLOW_MESH_NAME],
    transparent: true,
    textureName: TexturesNames.marker,
    opacity: 0.2
  },
  [MaterialsNames.gates]: {
    textureName: TexturesNames.gates,
    meshesArray: ['Branka0L', 'Branka0R', 'BrankyCombined', 'Branka_0R', 'Branka_0L']
  },
  [MaterialsNames.startCabin]: {
    textureName: TexturesNames.startCabin,
    meshesArray: ['StartCabin'],
    lightmap: TexturesNames.lightmapHill
  }
}
